function extractVueFunctions () {
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"frontmatter-markdown"},[_c('h4',[_vm._v("Hey there! Latin Jackey here. I'm a developer from China.")]),_vm._v(" "),_c('h4',[_vm._v("I'm interested in technologies such as")]),_vm._v(" "),_c('h3',[_c('font',{attrs:{"color":"#008000"}},[_c('strong',[_vm._v("JavaScript / TypeScript, Vue / Uni-app, Golang, Object-C / Swift and Flutter")])])],1),_vm._v(" "),_c('h4',[_vm._v("Some of my projects can be found on the project page and my GitHub homepage.")]),_vm._v(" "),_c('h4',[_vm._v("Feel free to reach me out to discuss tech / programming related stuff!")]),_vm._v(" "),_vm._m(0),_vm._v(" "),_vm._m(1),_vm._v(" "),_vm._m(2),_vm._v(" "),_vm._m(3)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_c('p',{attrs:{"align":"left"}},[_vm._v("Work Experience")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pre',[_c('code',{pre:true,attrs:{"class":"hljs"}},[_vm._v("全友家居资深软件工程师\n\n"),_c('span',{pre:true,attrs:{"class":"hljs-number"}},[_vm._v("58")]),_vm._v("（成都）花椒车服项目组软件工程师\n\n重庆华医集团前端开发组组长\n\n重庆国泰集团iOS开发组长\n\n")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',[_c('p',{attrs:{"align":"left"}},[_vm._v("Educational Experience")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pre',[_c('code',{pre:true,attrs:{"class":"hljs"}},[_vm._v("南阳师范学院"),_c('span',{pre:true,attrs:{"class":"hljs-comment"}},[_vm._v("(本科)")]),_vm._v("\n\n新乡学院"),_c('span',{pre:true,attrs:{"class":"hljs-comment"}},[_vm._v("(专科)")]),_vm._v("\n")])])}]

return { render: render, staticRenderFns: staticRenderFns }
}
const vueFunctions = extractVueFunctions()

module.exports = { 
      attributes: {},
    
      vue: {
        component: {
          data: function () {
            return {
              templateRender: null
            }
          },
          render: function (createElement) {
            return this.templateRender ? this.templateRender() : createElement("div", "Rendering");
          },
          created: function () {
            this.templateRender = vueFunctions.render;
            this.$options.staticRenderFns = vueFunctions.staticRenderFns;
          }
        }
      },
     }